<template>
    <div class="bg-white box-shadow border-r px-20 pb-20" v-loading="loading">
        <div class="d-flex-center border-bottom">
            <h4 class="mr-auto color-333">{{ $t('account.transactions') }}</h4>
            <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchRecord()">{{ $t('refresh') }}</el-button>
        </div>
        <el-row :gutter="10" type="flex" justify="center" align="middle" class="market-header px-20 py-15 border-bottom font-12">
            <el-col :span='3'>{{ $t('spot.buy') }}/{{ $t('spot.sell') }}</el-col>
            <el-col :span='4'>{{ $t('market.symbol') }}</el-col>
            <el-col :span='4'>{{ $t('price') }}</el-col>
            <el-col :span='4'>{{ $t('amount') }}</el-col>
            <el-col :span='5'>{{ $t('date_time') }}</el-col>
            <el-col class="text-right" :span='4'>{{ $t('status') }}</el-col>
        </el-row>
        <div v-if="list.data.length">
            <el-row v-for="(item, index) in list.data" :key="index" :gutter="10" type="flex" justify="center" align="middle" class="market-header px-20 py-15 border-bottom font-14">
                <el-col :span='3'>
                    <el-tag v-if="item.type" type="info" effect="plain" hit class="bg-t">Sell</el-tag>
                    <el-tag v-else type="primary" effect="plain" hit class="bg-t">Buy</el-tag>
                </el-col>
                <el-col :span='4'>{{ item.pair }} / {{ item.coin }}</el-col>
                <el-col :span='4'>{{ item.price }}</el-col>
                <el-col :span='4'>{{ item.amount }}</el-col>
                <el-col :span='5'>{{ item.create_time }}</el-col>
                <el-col class="text-right" :span='4'>
                    <el-tag :type="state[item.state][0]" effect="plain" hit class="bg-t">{{ state[item.state][1] }}</el-tag>
                </el-col>
            </el-row>
        </div>
        <el-empty v-else :image-size="100" description="No Data"></el-empty>
        <div class="d-flex-center pt-20">
            <el-pagination
                :hide-on-single-page="true"
                background
                layout="prev, pager, next"
                :page-size="list.per_page"
                :current-page="list.current_page"
                :total="list.total"
                @current-change="onPage"
            />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            list: {
                per_page: 1,
                last_page: 1,
                current_page: 1,
                total: 0,
                data: []
            },
            state: [
                ['primary', 'Trading'],
                ['info', 'Cancel'],
                ['success', 'Deal']
            ]
        }
    },
    created: function() {
        this.fatchRecord()
    },
    methods: {
        fatchRecord: async function () {
            this.loading = true
            const { data } = await this.$request.get('transaction/order_pc')
            this.list = data
            this.loading = false
        },
        onSearch() {
            this.fatchRecord()
            this.$router.replace({
                query: this.query
            })
        },
        onPage(page) {
            this.query.page = page || 1
            this.onSearch()
        }
    }
}
</script>
<style lang="scss" scoped>
//
</style>